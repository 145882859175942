import React from 'react'
import {WeChat} from '../../images'
import { WeChatButton } from '../Footer/FooterElements'
import styled from 'styled-components'

const QRCodeContainer = styled.img `
  width: 70%;
  height: auto;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
`

export const QRCodePopup = () => {
  return (
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              微信
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <QRCodeContainer src={WeChat}/>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
