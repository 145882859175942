import styled from 'styled-components';
import {Link as LinkR} from "gatsby";
import { Link as LinkS } from "react-scroll";
// import AniLink from "gatsby-plugin-transition-link/AniLink";


export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? '#000' : 'rgba(0,0,0,0.7)')};
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 309;
  margin: 0px;
  transition: 0.8s all ease;
`

export const NavbarContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  height: 60px;
  z-index: 301;
  width: 100%;
  margin: 0;
  max-width: 1400px;
`

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  left: 20px;
  top: 5px;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  width: calc(60px + 3vw);
  z-index: 310;
`

export const MobileIcon = styled.div`
  display: none;
  color: white;

  @media screen and (max-width: 1100px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-80%, 10%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-top: 20px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`

export const SubNavBar = styled.div`
  transition: all 0.2s ease-in-out;
  display: none;
  position: absolute;
  left: 0;
  background-color: rgba(31, 29, 29, 0.664);
  width: 100%;
  z-index: -1;
  height: 300%;
`

export const NavItem = styled.div`
  height: 60px;
  &:hover ${SubNavBar}{
    display: list-item;
    transition: all 0.2s ease-in-out;
  }
  z-index: 102;
  font-size: 14px;

`

export const SubNavItem = styled.a`
  margin: 5%;
  font-size: 20px;
  float: inline-end;
  color: white;
  text-decoration: none;
  vertical-align: auto;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #eee;
    color: black;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`

export const NavLink = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 0.9rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    border-bottom: 3px solid #01bf71;
    text-decoration: none;
    color: white;
  }
  &:active {
    border-bottom: 3px solid #01bf71;
  }
  z-index: 302;
`

export const ScrollNavLink = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 0.9rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    border-bottom: 3px solid #01bf71;
    text-decoration: none;
    color: white;
  }
  &:active {
    border-bottom: 3px solid #01bf71;
  }
`

export const SpecialNavLink = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 0.9rem;
  height: 100%;
  cursor: pointer;
  background-color: #ec008c;
  border-radius: 5px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #ec008c;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`

export const ScrollBtnLink = styled(LinkS)`
  border-radius: 50px;
  background: #ec008c;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`