import React from "react"
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa"
import {
  SocialIcons,
  SocialIconLink,
  VerticalSocialIcons,
  WeChatButton
} from "./FooterElements"
import { Icon, InlineIcon } from "@iconify/react"
import wechatIcon from "@iconify-icons/fa/wechat"


export const Icons = () => {
  return (
    <SocialIcons>
      <SocialIconLink
        href="https://www.facebook.com/studylinks.co.uk/"
        target="_blank"
        aria-label="Facebook"
      >
        <FaFacebook />
      </SocialIconLink>
      <SocialIconLink
        href="https://www.youtube.com/channel/UCrEFAt1B_jSZrtz8XAXbuLg"
        target="_blank"
        aria-label="Youtube"
        rel="noopener noreferrer"
      >
        <FaYoutube />
      </SocialIconLink>
      <SocialIconLink
        target="_blank"
        aria-label="Twitter"
        href="https://www.instagram.com/studylinks/"
      >
        <FaInstagram />
      </SocialIconLink>
      <SocialIconLink
        href="https://www.linkedin.com/company/study-links-international-ltd/"
        target="_blank"
        aria-label="Linkedin"
      >
        <FaLinkedin />
      </SocialIconLink>
      <WeChatButton
        type="button"
        data-toggle="modal"
        data-target="#exampleModal"
      >
         <Icon icon={wechatIcon} />
      </WeChatButton>
    </SocialIcons>
  )
}

export const VerticalIcons = () => {
  return (
    <VerticalSocialIcons>
      <SocialIconLink
        href="https://www.facebook.com/studylinks.co.uk/"
        target="_blank"
        aria-label="Facebook"
      >
        <FaFacebook />
      </SocialIconLink>
      <SocialIconLink
        href="https://www.youtube.com/channel/UCrEFAt1B_jSZrtz8XAXbuLg"
        target="_blank"
        aria-label="Youtube"
        rel="noopener noreferrer"
      >
        <FaYoutube />
      </SocialIconLink>
      <SocialIconLink
        target="_blank"
        aria-label="Twitter"
        href="https://www.instagram.com/studylinks/"
      >
        <FaInstagram />
      </SocialIconLink>
      <SocialIconLink
        href="https://www.linkedin.com/company/study-links-international-ltd/"
        target="_blank"
        aria-label="Linkedin"
      >
        <FaLinkedin />
      </SocialIconLink>
      <SocialIconLink
        href="weixin://contacts/profile/study-links"
        target="_blank"
        aria-label="Linkedin"
      >
        <Icon icon={wechatIcon} />
      </SocialIconLink>
    </VerticalSocialIcons>
  )
}
