import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkBigItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from './FooterElements';
import { Icon, InlineIcon } from '@iconify/react';
import wechatIcon from '@iconify-icons/fa/wechat';
import {Icons} from './Social'

// import { Nav, FooterContainer } from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinkBigItems>
            <FooterLinkTitle>
Study Links International, 6 Carr Lane,
Bradford, West Yorkshire, BD12 0QS, UK
            </FooterLinkTitle>
            <FooterLinkTitle>
            英國註冊編號：6625079
            </FooterLinkTitle>
          </FooterLinkBigItems>
          <FooterLinkBigItems>
            <FooterLinkTitle>
            電子郵件: <a href={"mailto:hello@studylinks.co.uk"}>hello@studylinks.co.uk</a>
            </FooterLinkTitle>
            <FooterLinkTitle>
            電話: <a href="tel: +4401274679710">+44 (0)1274 679710</a>
            </FooterLinkTitle>
          </FooterLinkBigItems>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Study Links
            </SocialLogo>
            <WebsiteRights>Studylinks © 2021 保留所有權利</WebsiteRights>
            <Icons />
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
