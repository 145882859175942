import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import { Link } from "gatsby";

export const MNBMenu = styled.ul `
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9,60px);
  text-align: center;

  @media screen and (max-width: 480px){
    grid-template-rows: repeat(9,50px);
  }

  @media screen and (max-height: 530px){
    grid-template-rows: repeat(9,60px);

  }
`;

export const MNBIconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MNBContainer = styled.aside `
  position: fixed;
  z-index: 311;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  /* display: ${({isOpen}) => (isOpen ? 'flex' : 'none')}; */
  display: grid;
  align-items; center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
  top: ${({isOpen}) => (isOpen ? '0' : '-200%')};
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`

export const Icon = styled.div `
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`
export const MNBWrapper = styled.div `
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 3rem; */

`

export const MNBLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
`