import React from 'react'
import Logo from "../../images/logo.png";
import Poster from "../../images/Poster.png";
import styled from "styled-components";

export const TestimonyContainer = styled.div`
  height: 300px;
  position: relative;
  /* background-color: #0B0040; */
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`

export const TestimonyQuote = styled.div`
  color: white;
  font-size: calc(0.55rem + 0.7vw);
  font-style: italic;
  margin-left: calc(30px + 7vw);
  margin-right: calc(30px + 7vw);
  `
export const TestimonyAuthor = styled.div`
  color: #bbb;
  font-size: calc(0.5rem + 0.5vw);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  `

export const InsertedTestimonyContainer = styled.div`
    height: 300px;
    position: relative;
    /* background-color: #0B0040; */
    background-color: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  `

export const InsertedTestimonyQuote = styled.div`
  color: black;
  font-size: calc(0.7rem + 0.7vw);
  font-style: italic;
  margin-left: calc(30px + 7vw);
  margin-right: calc(30px + 7vw);
`
export const InsertedTestimonyAuthor = styled.div`
  color: #bbb;
  font-size: calc(0.5rem + 0.5vw);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Testimony = (props) => {
  const {quote, author} = props
  return (
    <TestimonyContainer>
      <TestimonyQuote>
        {quote}
      </TestimonyQuote>
      <TestimonyAuthor>
        {author}
      </TestimonyAuthor>
    </TestimonyContainer>
  )
}

export const InsertedTestimony = (props) => {
  const {quote, author} = props
  return (
    <InsertedTestimonyContainer>
      <InsertedTestimonyQuote>
        {quote}
      </InsertedTestimonyQuote>
      <InsertedTestimonyAuthor>
        {author}
      </InsertedTestimonyAuthor>
    </InsertedTestimonyContainer>
  )
}

export const Carousel = ({children}) => {
  return (
    <div id="carouselTestimonial" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
      <div class="carousel-item active">
          <Testimony quote="The homestay programme offered by Study Links International is excellent. It provides good, appropriate host families and accommodation for the pupils in its care and is efficient and contactable at all times." author="Head of Boarding, Harrogate Ladies College"/>
        </div>
        <div class="carousel-item">
          <Testimony quote="Study Links International has proved utterly dependable, thorough and caring in their approach to the children in their charge." author="Headmaster, Rishworth School, Halifax."/>
        </div>
        <div class="carousel-item">
          <Testimony quote="I am writing to thank you for helping to organise the tuition for Quincy. My wife and I are indeed happy with the arrangements. Quincy found the tuitions helpful and is making progress at school." author="Mr Kwok, Parent of Ronson"/>
        </div>
        <div class="carousel-item">
        <Testimony quote="Thank you for the arrangements by your team. My son went to UK last year for studying Yr. 7 in Barnard Castle School. Fortunately, Study Link helped him to find a suitable homestays during the each half term break despite I made few requests." author="Mrs Liu, Parent of Ernest"/>
        </div>
        <div class="carousel-item">
        <Testimony quote="The Study Links team were always available by email or telephone and were always willing to help me. I would recommend this service to other parents." author="Mrs Wang, Parent of Dominic"/>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselTestimonial" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselTestimonial" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  );
};
