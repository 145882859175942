export const story1 = require("./about/story1.png")
export const story2 = require("./about/story2.png")
export const story3 = require("./about/story3.png")
export const story4 = require("./about/story4.png")
export const story5 = require("./about/story5.png")
export const story6 = require("./about/story6.png")
export const story7 = require("./about/story7.png")
export const story8 = require("./about/story8.png")
export const story9 = require("./about/story9.png")
export const story10 = require("./about/story10.png")
export const logo = require("./logo.png")
export const poster = require("./Poster.png")
export const news = require("./news.png")
export const AngelaFan = require("./about/AngelaFan.png")
export const AilieFan = require("./about/AilieFan.png")
export const AnnieWang = require("./about/AnnieWang.png")
export const DianneSenior = require("./about/DianneSenior.png")
export const EricFan = require("./about/EricFan.png")
export const GabrielaSeczkowska = require("./about/GabrielaSeczkowska.png")
export const JodieDriver = require("./about/JodieDriver.png")
export const PaulaJureczeko = require("./about/PaulaJureczeko.png")
export const SarahSingleton = require("./about/SarahSingleton.png")
export const YueruiSun = require("./about/YueruiSun.png")
export const ZoePilling = require("./about/ZoePilling.png")
export const ChristineKwong = require("./about/ChristineKwong.jpg")
export const hero1 = require("./hero/hero1.jpg")
export const hero2 = require("./hero/hero2.jpg")
export const hero3 = require("./hero/hero3.jpg")
export const hero4 = require("./hero/hero4.jpg")
export const hero5 = require("./hero/hero5.jpg")
export const hero6 = require("./hero/hero6.jpg")
export const award1 = require("./awards/award1.png")
export const award2 = require("./awards/award2.png")
export const award3 = require("./awards/award3.png")
export const award4 = require("./awards/award4.png")
export const award5 = require("./awards/award5.png")
export const award6 = require("./awards/award6.png")
export const award7 = require("./awards/award7.png")
export const award8 = require("./awards/award8.png")
export const award9 = require("./awards/award9.png")
export const family_business = require("./awards/family-business.png")
export const accreditations1 = require("./accreditations/accreditations1.png")
export const accreditations2 = require("./accreditations/accreditations2.png")
export const accreditations3 = require("./accreditations/accreditations3.png")
export const accreditations4 = require("./accreditations/accreditations4.png")
export const accreditations5 = require("./accreditations/accreditations5.png")
export const accreditations6 = require("./accreditations/accreditations6.png")
export const accreditations7 = require("./accreditations/accreditations7.png")
export const accreditations8 = require("./accreditations/accreditations8.png")
export const about = require("./home/about.svg")
export const guardian = require("./home/guardian.svg")
export const homestay = require("./home/homestay.svg")
export const blog = require("./home/blog.svg")
export const workwithus = require("./home/workwithus.svg")
export const contact = require("./home/contact.svg")
export const educationServices = require("./home/educationServices.svg")
export const supportServices = require("./home/supportServices.svg")
export const guardianshipContact = require ("./guardianship/contact.svg")
export const guardianshipApply = require ("./guardianship/apply.svg")
export const blogBanner = require("./blog/banner.jpg")
export const contactBanner = require("./contact/banner.jpg")
export const homestayBanner = require("./homestay/banner.jpg")
export const guardianshipBanner = require("./guardianship/banner.jpg")
export const workwithusBanner = require("./workwithus/banner.jpg")
export const aboutBanner = require("./about/banner.jpg")
export const educationBanner = require("./education/banner.jpg")
export const supportBanner = require("./support/banner.jpg")
export const guardianshipGif = require('./guardianship/Guardianship.gif')
export const homestayGif = require('./homestay/WhatIsHomestay.gif')
export const becomeHomestayGif = require('./workwithus/Homestay.gif')
export const AIExcellence = require('./awards/2017AI.png')
export const CEOToday = require('./awards/CEOToday.png')
export const AnnAustin = require('./about/AnnAustin.jpg')
export const BridgetLane = require('./about/BridgetLane.jpg')
export const BryanChadderton = require('./about/BryanChadderton.jpg')
export const ChrisHolsman = require('./about/ChrisHolsman.jpg')
export const ClareBakerSmith = require('./about/ClareBakerSmith.jpg')
export const DenisSavage = require('./about/DenisSavage.jpg')
export const HarrietJohnsonTsui = require('./about/HarrietJohnsonTsui.jpg')
export const KatieSchad = require('./about/KatieSchad.jpg')
export const LouiseHarrison = require('./about/LouiseHarrison.jpg')
export const MichaelGrive = require('./about/MichaelGrive.jpg')
export const NataljaFakirova = require('./about/NataljaFakirova.jpg')
export const NikMacKenzie = require('./about/NikMacKenzie.jpg')
export const PhilDavis = require('./about/PhilDavis.jpg')
export const TamsinBailey = require('./about/TamsinBailey.jpg')
export const YingZhang = require('./about/YingZhang.jpg')
export const BlankPortrait = require('./about/BlankPortrait.jpg')
export const WeChat = require('./WeChatQRCode.jpg')