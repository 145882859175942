import React from 'react'
import {InsertedTestimony, Testimony} from './TestimonialElements'
import Carousel from 'react-bootstrap/Carousel'
export {InsertedTestimony}

export const HomepageTestimony = () => {
  return (
    <Testimony quote="事實證明，Study Links International 對待所負責的孩子的態度是完全可靠的，他們對孩子的管理面面俱到並且充滿關懷。" author="瑞思沃思學校校長"/>
  )
}

export const AboutTestimony = () => {
  return (
    <Testimony quote="海外家庭發現他們的問題和任何疑慮都會得到及時和善意的處理。學校、寄宿家庭和家長對有效溝通非常積極，他們感到 Study Links 的專業行動有效地支持了他們。" author="AEGIS 複檢，2021 年"/>
  )
}

export const EducationTestimony = () => {
  return (
    <Testimony quote="我寫信是爲了感謝您幫助安排對昆西的個人指導。我和妻子對這些安排確實很滿意。昆西覺得這些指導很有用，並在學校裏取得了進步。" author="Ronson的家長郭先生"/>
  )
}

export const SupportTestimony = () => {
  return (
    <Testimony quote="非常感謝 Study Links如果沒有 Study Links，就不會有今天的我。我向你們致以良好的祝願，我很高興看到越來越多的學生得到我曾經擁有的那份幸運和指導。" author="Dominic Wang - 聖母初級學院學生"/>
  )
}

export const TestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
        <Testimony quote="Study Links International 提供了非常出色的家庭寄宿計劃。它爲受其照顧的學生提供良好、合適的寄宿家庭和住宿環境，並且始終高效且可聯。." author="哈羅蓋特女子學院寄宿主任" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="事實證明，Study Links International 對待所負責的孩子的態度是完全可靠的，他們對孩子的管理面面俱到並且充滿關懷。" author="瑞思沃思學校校長"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="我寫信是爲了感謝您幫助安排對昆西的個人指導。我和妻子對這些安排確實很滿意。昆西覺得這些指導很有用，並在學校裏取得了進步。" author="Ronson的家長郭先生"/>
      </Carousel.Item>
    </Carousel>
    );
};

export const GuardianshipTestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
        <Testimony quote="把孩子送到英國對我來說是一個很難的決定。但有了 Study Links 提供的貼心和專業的監護服務，我相信我的孩子一切會順利的。他們關注學業進步，這對我來說非常重要，並就如何改進和繼續在學校發展提出了想法。通過電子郵件或電話就可以聯繫到團隊，他們總是願意幫助我。我會向其他家長推薦這項服務。" author="王太太 - 家長" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="毫無疑問，在英國學習期間，Study Links 給了我最好的照顧。我覺得我像是加入了一個大家庭。我結交了的一些終生難忘的朋友，他們來自世界各地。在英國的學校現在感覺就像在家一樣，我得到了我需要的所有幫助。當我第一次來到英國時，Study Links 幫助我很快適應了這個全新的環境。它不僅僅是監護，更像是“家庭之船”。." author="Sam Chen – 阿克沃思學校的學生"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="如果我在英國遇到任何問題，我要做的第一件事就是告訴 Study Links，因爲他們會給你解決問題的方法。" author="Billy Kwong – 弗靈霍爾學校學生"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="初到英國，我對陌生的環境和麪孔感到震驚，這讓我感到焦慮和想家。Study Links 給予了我在英國學習的信心。我校的其他學生也對 Study Links 給予了很好的評價。我很高興成爲他們的學生之一。" author="Theresa Liang – 埃塞爾堡女王學院 "/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="我非常看重 Study Links 的判斷力和專業精神，從不爲註冊學生擔心。" author="P Shield 女士 - 哈羅蓋特女子學院寄宿主任"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="我的女兒已經被安吉拉和她的團隊的照顧了四年。由於他們的專業經驗和務實精神，團隊對我女兒的照顧恰到好處，沒有寵壞她，在她在英國的學習期間中給她進行了限定，從而加強了她的獨立思考能力。儘管如此，在需要迅速干預和幫助的特殊情況下，Study Links 非常適合我女兒的直接和長期利益。" author="Vivian Cheung – 家長"/>
      </Carousel.Item>
    </Carousel>
    );
};
export const HomestayTestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
      <Testimony quote="Study Links International 提供了非常出色的家庭寄宿計劃。它爲受其照顧的學生提供良好、合適的寄宿家庭和住宿環境，並且始終高效且可聯。." author="哈羅蓋特女子學院寄宿主任" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="如果沒有 Study Links，我女兒到英國時可能會走丟。從第一天起，他們就提供了父母想要給孩子但卻因距離太遠無法給予的幫助和支持。他們爲我女兒找到了一個很棒的寄宿家庭，每次住宿都安排得井井有條。謝謝 Study Links！" author="陳太太 - 家長"/>
      </Carousel.Item>
    </Carousel>
    );
};
export const WorkwithusTestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
        <Testimony quote="I am honoured and privileged to be part of such an organisation as Study Links as they don’t just take the finest care of all their students but their host families too." author="Cath- Homestay" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="Study Links 一直是一家專業且積極進取的公司，運營效率高，且對所負責的海外學生充滿關懷和照顧。" author="雷德學校校長"/>
      </Carousel.Item>
    </Carousel>
    );
};